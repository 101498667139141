import { createSlice, createAsyncThunk, isPending } from '@reduxjs/toolkit';
import pLimit from 'p-limit';
import fetchUtility from '../fetchUtility';
import { SERVER_URL } from '../../admin/constants/Constants';
import { listItem, getfile, uploadfile } from '../../utils/s3';

export const getProjectsListActionCreator = createAsyncThunk('projects/list', async (payload) => {
  const { data } = await fetchUtility('get', `${SERVER_URL.PROJECT_LIST}`, payload);
  return data;
});

export const getProjectsAddActionCreator = createAsyncThunk('projects/add', async (payload) => {
  const { data, status } = await fetchUtility(
    'get',
    `${SERVER_URL.MY_PROJECT_ADD}${payload.id}`,
    payload
  );
  return { data, status };
});

export const getProjectsRemoveActionCreator = createAsyncThunk(
  'projects/remove',
  async (payload) => {
    const { data, status } = await fetchUtility(
      'get',
      `${SERVER_URL.MY_PROJECT_REMOVE}${payload.id}`,
      payload
    );
    return { data, status };
  }
);

export const getProjectActionCreator = createAsyncThunk('project/list', async (payload) => {
  const { data } = await fetchUtility('get', `${SERVER_URL.PROJECT_LIST}/${payload}`, payload);
  return data;
});

export const createProjectActionCreator = createAsyncThunk('projects/create', async (payload) => {
  const { data } = await fetchUtility(
    'post',
    `${SERVER_URL.PROJECT_LIST}/${payload.project.summary.projectId}`,
    payload
  );
  return data;
});

export const editProjectActionCreator = createAsyncThunk(
  'projects/edit',
  async ({ projectId, payload }) => {
    const { data } = await fetchUtility('put', `${SERVER_URL.PROJECT_LIST}/${projectId}`, payload);
    return data;
  }
);

export const addProjectMediaActionCreator = createAsyncThunk('projects/media', async (payload) => {
  const { projectId } = payload;
  delete payload.projectId;
  const { data } = await fetchUtility('put', `${SERVER_URL.PROJECT_MEDIA}/${projectId}`, payload);
  return data;
});

export const getProjectMediaActionCreator = createAsyncThunk(
  'projects/getmedia',
  async (payload) => {
    const { projectId } = payload;
    const { data } = await fetchUtility('get', `${SERVER_URL.PROJECT_MEDIA}/${projectId}`);
    return data;
  }
);

export const getProjectUploadActionCreator = createAsyncThunk(
  'projects/uploadData',
  async (payload) => {
    const { projectId } = payload;
    const data = await listItem(projectId, process.env.REACT_APP_S3_MEDIA_BUCKET_NAME);
    return data;
  }
);

export const getCoordinateActionCreator = createAsyncThunk(
  'projects/coordinate',
  async (payload) => {
    const { filePath } = payload;
    const data = await getfile(filePath, process.env.REACT_APP_S3_MEDIA_BUCKET_NAME);
    return data;
  }
);

export const deleteProjectRouteActionCreator = createAsyncThunk(
  'projects/routes',
  async (payload) => {
    delete payload.projectId;
    const { data } = await fetchUtility('delete', `${SERVER_URL.PROJECT_UPLOAD_MEDIA}`, payload);
    return data;
  }
);

export const deleteProjectFileActionCreator = createAsyncThunk(
  'projects/routes',
  async (payload) => {
    const { projectId } = payload;
    delete payload.projectId;
    const { data } = await fetchUtility(
      'delete',
      `${SERVER_URL.PROJECT_MEDIA}/${projectId}`,
      payload
    );
    return data;
  }
);

export const addCordinateFrames = createAsyncThunk('projects/cordinateframes', async (payload) => {
  const { data } = await fetchUtility('post', `${SERVER_URL.PROJECT_COR_FRAME}`, payload);
  return data;
});

export const triggerBatchingLamnda = createAsyncThunk(
  'projects/batchinglamnda',
  async (payload) => {
    const { data } = await fetchUtility('post', `${SERVER_URL.PROJECT_BATCHING_LAMNDA}`, payload);
    return data;
  }
);

export const deleteAnotation = createAsyncThunk('projects/deleteAnotation', async (payload) => {
  const { data } = await fetchUtility('post', `${SERVER_URL.PROJECT_DELETE_ANOTATION}`, payload);
  return data;
});

export const reorderRoute = createAsyncThunk('projects/reorderRoute', async (payload) => {
  const { data } = await fetchUtility('post', `${SERVER_URL.REORDER_ROUTE}`, payload);
  return data;
});
// export const fileUploadActionCreator = createAsyncThunk(
//   'projects/fileupload',
//   async (payload, state) => {
//     const startTime = Date.now();
//     const { file, projectName, projectId } = payload;
//     let noOfFileFinished = 0;
//     const folderName = `${projectId}_zip_${Date.now()}`;
//     const progressFunction = (p, numParts) => {
//       if ((p.loaded * 100) / p.total === 100) {
//         noOfFileFinished += 1;
//       }
//       const progress =
//         (noOfFileFinished * 100) / numParts >= 100 ? 99 : (noOfFileFinished * 100) / numParts;
//       const uploadObj = {
//         uploadProgress: progress,
//         isUploading: true,
//         projectName
//       };
//       // eslint-disable-next-line no-use-before-define
//       state.dispatch(updateUploadProgressBar(uploadObj));
//     };
//     const promisArr = [];
//     const filePath = `${projectId}/${folderName}/frames/`;
//     // eslint-disable-next-line no-restricted-syntax, guard-for-in
//     for (const x in file) {
//       const newfilePath = filePath + file[x].name;
//       promisArr.push(
//         uploadMultipart(
//           newfilePath,
//           file[x],
//           progressFunction,
//           process.env.REACT_APP_S3_MEDIA_BUCKET_NAME,
//           file.length
//         )
//       );
//     }

//     await Promise.all(promisArr);
//     const endTime = Date.now();
//     const timeTaken = endTime - startTime; // Time in milliseconds
//     console.log(`Time taken: ${timeTaken} ms`);
//     // uploadObj.timeTaken = timeTaken;
//     state.dispatch(
//       triggerBatchingLamnda({
//         project_id: projectId,
//         key: folderName
//       })
//     );
//     return { timeTaken };
//   }
// );

export const fileUploadActionCreator = createAsyncThunk(
  'projects/fileupload',
  async (payload, state) => {
    const startTime = Date.now();
    const { file, projectName, projectId } = payload;
    let noOfFileFinished = 0;
    const folderName = `${projectId}_zip_${Date.now()}`;
    const updateProgress = () => {
      noOfFileFinished += 1;
      const progress = (noOfFileFinished / file.length) * 100;
      const uploadObj = {
        uploadProgress: progress >= 100 ? 99 : progress,
        isUploading: true,
        projectName
      };
      // eslint-disable-next-line no-use-before-define
      state.dispatch(updateUploadProgressBar(uploadObj)); // Dispatch progress update
    };
    const filePath = `${projectId}/${folderName}/frames/`;
    const chunkSize = 5;
    const totalChunks = Math.ceil(file.length / chunkSize);
    const limit = pLimit(500); // Set up concurrency limit

    // Collect all promises for all chunks
    const allChunkUploadPromises = [];
    // eslint-disable-next-line no-plusplus
    for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
      const chunk = Array.from(file).slice(chunkIndex * chunkSize, (chunkIndex + 1) * chunkSize);
      // eslint-disable-next-line no-shadow
      const chunkUploadPromises = chunk.map((file) => {
        const newfilePath = filePath + file.name;
        return limit(() =>
          uploadfile(newfilePath, file, process.env.REACT_APP_S3_MEDIA_BUCKET_NAME, updateProgress)
        );
      });
      allChunkUploadPromises.push(...chunkUploadPromises);
    }
    try {
      // Execute all upload promises concurrently
      // eslint-disable-next-line no-unused-vars
      await Promise.all(allChunkUploadPromises);
      // results.forEach(() => {
      //   // console.log(`File uploaded successfully: ${JSON.stringify(result)}`);
      // });
    } catch (error) {
      console.error('Error uploading files:', error);
    }
    const endTime = Date.now();
    const timeTaken = endTime - startTime; // Time in milliseconds
    console.log(`Time taken: ${timeTaken / 60000} ms`);
    state.dispatch(
      triggerBatchingLamnda({
        project_id: projectId,
        key: folderName
      })
    );
    return { timeTaken };
  }
);

const isPendingAction = isPending(getProjectsListActionCreator, getProjectMediaActionCreator);
const isPendingUploadListAction = isPending(getProjectUploadActionCreator);
const isPendingCoordinateAction = isPending(getCoordinateActionCreator);
const isPendingUploading = isPending(fileUploadActionCreator);
const isPendingaddCordinateFrames = isPending(addCordinateFrames);

export const projectSlice = createSlice({
  name: 'projects',
  initialState: {
    list: [],
    loading: null,
    error: null,
    messageData: null,
    projectMedia: [],
    projectUpload: null,
    loadUploadList: null,
    mapCordinate: { data: [], error: false },
    loadCoordinate: false,
    uploadData: [],
    loadAddCordinateFrames: false
  },
  reducers: {
    clearMessage: {
      reducer: (state) => {
        state.messageData = null;
      }
    },
    updateMapCordinate: {
      reducer: (state, payload) => {
        state.mapCordinate = { data: payload.payload, error: false };
      }
    },
    updateUploadProgress: {
      reducer: (state, payload) => {
        const uploadData = [...state.uploadData];
        const index = uploadData.findIndex((ele) => ele.file === payload.payload.file);
        uploadData[index] = payload.payload;
        state.uploadData = uploadData;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getProjectsListActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action?.payload?.data;
    });
    builder.addCase(createProjectActionCreator.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(editProjectActionCreator.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(addProjectMediaActionCreator.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getProjectMediaActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.projectMedia = action?.payload?.data;
    });
    builder.addCase(getProjectUploadActionCreator.fulfilled, (state, action) => {
      state.loadUploadList = false;
      state.projectUpload = action?.payload;
    });
    builder.addCase(getCoordinateActionCreator.fulfilled, (state, action) => {
      state.loadCoordinate = false;
      if (!action.payload.error) {
        action.payload.data = JSON.parse(action?.payload.data.replace(/\\s+/g, ''));
      }
      state.mapCordinate = action.payload;
    });
    builder.addCase(getProjectsAddActionCreator.fulfilled, (state, action) => {
      state.messageData = action?.payload;
      const projectIndex = state.list.findIndex(
        (project) => project?.info?.project_id === action.payload.data.projectId
      );
      if (projectIndex > -1) {
        state.list[projectIndex].selected = true;
      }
    });
    builder.addCase(getProjectsRemoveActionCreator.fulfilled, (state, action) => {
      state.messageData = action?.payload;
      const projectIndex = state.list.findIndex(
        (project) => project?.info?.project_id === action.payload.data.projectId
      );
      if (projectIndex > -1) {
        state.list[projectIndex].selected = false;
      }
    });
    builder.addCase(fileUploadActionCreator.fulfilled, (state, action) => {
      const uploadObj = {
        uploadProgress: 100,
        isUploading: false,
        projectName: action.meta.arg.projectName,
        timeTaken: action.payload.timeTaken
      };
      const uploadData = [...state.uploadData];
      const index = uploadData.findIndex((ele) => ele.file === uploadObj.file);
      uploadData[index] = uploadObj;
      state.uploadData = uploadData;
    });
    builder.addCase(addCordinateFrames.fulfilled, (state) => {
      state.loadAddCordinateFrames = false;
    });
    builder.addCase(getProjectsRemoveActionCreator.rejected, (state, action) => {
      state.messageData = action?.payload;
    });
    builder.addMatcher(isPendingAction, (state) => {
      state.loading = true;
    });
    builder.addMatcher(isPendingUploadListAction, (state) => {
      state.loadUploadList = true;
    });
    builder.addMatcher(isPendingCoordinateAction, (state) => {
      state.loadCoordinate = true;
    });
    builder.addMatcher(isPendingaddCordinateFrames, (state) => {
      state.loadAddCordinateFrames = true;
    });
    builder.addMatcher(isPendingUploading, (state, action) => {
      const uploadObj = {
        uploadProgress: 0,
        isUploading: true,
        projectName: action.meta.arg.projectName
      };
      const uploadData = [...state.uploadData];
      const index = uploadData.findIndex((ele) => ele.file === uploadObj.file);
      if (index > -1) {
        uploadData[index] = uploadObj;
      } else {
        uploadData.push(uploadObj);
      }
      state.uploadData = uploadData;
    });
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state, action) => {
        state.loading = false;
        state.loadUploadList = false;
        state.loadCoordinate = false;
        state.loadAddCordinateFrames = false;
        state.error = action.error.message;
      }
    );
  }
});

export const {
  clearMessage: clearMessageProjecstActionCreator,
  updateMapCordinate: updateMapCordinates,
  updateUploadProgress: updateUploadProgressBar
} = projectSlice.actions;

export default projectSlice.reducer;
