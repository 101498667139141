import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import './Loader.scss';

function Loader() {
  return (
    <div className="loader__container">
      <CircularProgress size="4rem" color="inherit" />
    </div>
  );
}

export default Loader;
