import { createSlice, createAsyncThunk, isPending } from '@reduxjs/toolkit';
import fetchUtility from '../fetchUtility';
import { SERVER_URL } from '../../admin/constants/Constants';

export const getAssetDetails = createAsyncThunk('assets/list', async () => {
  const { data } = await fetchUtility('get', `${SERVER_URL.ASSET_DETAILS}`);
  return data;
});

export const addAssetGroupDetails = createAsyncThunk('assets/add/group', async (payload) => {
  const { data } = await fetchUtility('post', `${SERVER_URL.ADD_ASSET_GROUP}`, payload);
  return data;
});

export const addAssetClassDetails = createAsyncThunk('assets/add/class', async (payload) => {
  const { data } = await fetchUtility('post', `${SERVER_URL.ADD_ASSET_CLASS}`, payload);
  return data;
});

export const updateAssetGroupDetails = createAsyncThunk('assets/update/group', async (payload) => {
  const { data } = await fetchUtility('put', `${SERVER_URL.UPDATE_ASSET_GROUP}`, payload);
  return data;
});

export const updateAssetClassDetails = createAsyncThunk('assets/update/class', async (payload) => {
  const { data } = await fetchUtility('put', `${SERVER_URL.UPDATE_ASSET_CLASS}`, payload);
  return data;
});

export const deleteAssetGroupDetails = createAsyncThunk('assets/delete/group', async (payload) => {
  const { data } = await fetchUtility('delete', `${SERVER_URL.DELETE_ASSET_GROUP}`, payload);
  return data;
});

export const deleteAssetClassDetails = createAsyncThunk('assets/delete/class', async (payload) => {
  const { data } = await fetchUtility('delete', `${SERVER_URL.DELETE_ASSET_CLASS}`, payload);
  return data;
});

export const deleteAssetClassItemsDetails = createAsyncThunk(
  'assets/delete/item',
  async (payload) => {
    const { data } = await fetchUtility('delete', `${SERVER_URL.DELETE_ASSET_ITEM}`, payload);
    return data;
  }
);

export const addObjectDetails = createAsyncThunk('assets/add/asset', async (payload) => {
  const { data } = await fetchUtility('post', `${SERVER_URL.ADD_ASSET_OBJECT}`, payload);
  return data;
});

export const addObjectAttributes = createAsyncThunk('assets/add/addObejctAttr', async (payload) => {
  const { data } = await fetchUtility('post', `${SERVER_URL.ADD_OBJECT_ATTRIBUTE}`, payload);
  return data;
});

export const updateObjectAttributes = createAsyncThunk(
  'assets/update/updateObjectAttr',
  async (payload) => {
    const { data } = await fetchUtility('put', `${SERVER_URL.UPDATE_OBJECT_ATTRIBUTE}`, payload);
    return data;
  }
);

export const deleteObjectAttributes = createAsyncThunk(
  'assets/delete/deleteObjectAttr',
  async (payload) => {
    const { data } = await fetchUtility('delete', `${SERVER_URL.DELETE_OBJECT_ATTRIBUTE}`, payload);
    return data;
  }
);

export const updateAllGroupHideDetails = createAsyncThunk(
  'assets/hideall/group',
  async (payload) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.UPDATE_ASSET_GROUP_ALL_HIDE}`,
      payload
    );
    return data;
  }
);

export const updateGroupHideDetails = createAsyncThunk('assets/hide/group', async (payload) => {
  const { data } = await fetchUtility('put', `${SERVER_URL.UPDATE_ASSET_GROUP_HIDE}`, payload);
  return data;
});

export const updateAllClassesHideDetails = createAsyncThunk(
  'assets/hideall/classes',
  async (payload) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.UPDATE_ASSET_CLASSES_ALL_HIDE}`,
      payload
    );
    return data;
  }
);

export const updateClassesHideDetails = createAsyncThunk('assets/hide/classes', async (payload) => {
  const { data } = await fetchUtility('put', `${SERVER_URL.UPDATE_ASSET_CLASSES_HIDE}`, payload);
  return data;
});

const isPendingAction = isPending(getAssetDetails);

export const assetsSlice = createSlice({
  name: 'assets',
  initialState: {
    assets: [],
    loading: false,
    error: false,
    success: false,
    getMessage: null
  },
  reducers: {
    clearGetMessage: {
      reducer: (state) => {
        state.getMessage = null;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(updateAllGroupHideDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.getMessage = action?.payload?.message;
      state.success = true;
    });
    builder.addCase(updateGroupHideDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.getMessage = action?.payload?.message;
      state.success = true;
    });
    builder.addCase(updateAllClassesHideDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.getMessage = action?.payload?.message;
      state.success = true;
    });
    builder.addCase(updateClassesHideDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.getMessage = action?.payload?.message;
      state.success = true;
    });
    builder.addCase(getAssetDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.assets = action?.payload?.data;
    });
    builder.addCase(addAssetGroupDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.getMessage = action?.payload?.message;
      state.success = true;
    });
    builder.addCase(addAssetClassDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.getMessage = action?.payload?.message;
      state.success = true;
    });
    builder.addCase(addObjectDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.getMessage = action?.payload?.message;
      state.success = true;
    });
    builder.addCase(addObjectAttributes.fulfilled, (state, action) => {
      state.loading = false;
      state.getMessage = action?.payload?.message;
      state.success = true;
    });
    builder.addCase(updateObjectAttributes.fulfilled, (state, action) => {
      state.loading = false;
      state.getMessage = action?.payload?.message;
      state.success = true;
    });
    builder.addCase(updateAssetGroupDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.getMessage = action?.payload?.message;
      state.success = true;
    });
    builder.addCase(deleteAssetGroupDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.getMessage = action?.payload?.message;
      state.success = true;
    });
    builder.addCase(updateAssetClassDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.getMessage = action?.payload?.message;
      state.success = true;
    });
    builder.addCase(deleteAssetClassDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.getMessage = action?.payload?.message;
      state.success = true;
    });
    builder.addCase(deleteAssetClassItemsDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.getMessage = action?.payload?.message;
      state.success = true;
    });
    builder.addMatcher(isPendingAction, (state) => {
      state.loading = true;
    });
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state, action) => {
        state.loading = false;
        state.error = true;
        state.getMessage = action.error.message;
      }
    );
  }
});

export const { clearGetMessage } = assetsSlice.actions;

export default assetsSlice.reducer;
