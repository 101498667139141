import { createSlice, createAsyncThunk, isPending } from '@reduxjs/toolkit';
import fetchUtility from '../fetchUtility';
import { SERVER_URL } from '../../admin/constants/Constants';

export const getMyProjectsListActionCreator = createAsyncThunk(
  'myProjects/list',
  async (payload) => {
    const { data } = await fetchUtility('get', SERVER_URL.MY_PROJECT_LIST, payload);
    return data;
  }
);

export const getMyProjectsRemoveActionCreator = createAsyncThunk(
  'myProjects/remove',
  async (payload) => {
    const { data, status } = await fetchUtility(
      'get',
      `${SERVER_URL.MY_PROJECT_REMOVE}${payload.id}`,
      payload
    );
    return { data, status };
  }
);

const isPendingAction = isPending(getMyProjectsListActionCreator);

export const myProjectSlice = createSlice({
  name: 'projects',
  initialState: {
    list: [],
    loading: null,
    error: null,
    messageData: null
  },
  reducers: {
    clearMessage: {
      reducer: (state) => {
        state.messageData = null;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getMyProjectsListActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action?.payload?.data;
    });
    builder.addCase(getMyProjectsRemoveActionCreator.fulfilled, (state, action) => {
      state.messageData = action?.payload;
      const projectIndex = state.list.findIndex(
        (project) => project?.info?.project_id === action.payload.data.projectId
      );
      if (projectIndex > -1) {
        state.list.splice(projectIndex, 1);
      }
    });
    builder.addCase(getMyProjectsRemoveActionCreator.rejected, (state, action) => {
      state.messageData = action?.payload;
    });
    builder.addMatcher(isPendingAction, (state) => {
      state.loading = true;
    });
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );
  }
});

export const { clearMessage: clearMessageMyProjecstActionCreator } = myProjectSlice.actions;

export default myProjectSlice.reducer;
