const translatedData = {
  'Sign in': 'Login',
  'Back to Sign In': 'Back to Login',
  'Forgot your password?': 'Forgot password?',
  'Incorrect username or password.': 'The email address or password you entered is incorrect.',
  'Reset your password': 'Forgot Password',
  'Signing in': 'Login',
  'Your passwords must match': 'Please make sure your passwords match',
  'Password does not conform to policy: Password not long enough':
    'Password entry does not meet the criteria',
  'Invalid verification code provided, please try again.': 'Invalid code. Please try again',
  'Username/client id combination not found.': 'Please enter a registered email address',
  'super-admin': 'Super Admin',
  'asset-manager': 'Asset Manager',
  admin: 'Admin',
  'civil-engineer': 'Civil Engineer',
  'Given Name': 'First Name',
  'Family Name': 'Last Name'
};
export default translatedData;
