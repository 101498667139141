import {
  S3Client,
  PutObjectCommand,
  ListObjectsV2Command,
  GetObjectCommand,
  DeleteObjectCommand
} from '@aws-sdk/client-s3';
import AWS from 'aws-sdk';

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION
});

export async function uploadLargefile(fullPath, file) {
  try {
    const presignedS3Url = s3.getSignedUrl('putObject', {
      Bucket: process.env.REACT_APP_S3_MEDIA_BUCKET_NAME,
      Key: fullPath,
      ContentType: file.type
    });
    return presignedS3Url;
  } catch (err) {
    return `Error uploading file: ${err}`;
  }
}

export async function uploadfile(fullPath, file, bucketname, updateProgress = () => {}) {
  const target = {
    Bucket: bucketname,
    Key: fullPath,
    Body: file,
    ContentType: file.type
  };
  const creds = {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
  };
  try {
    const client = new S3Client({ region: process.env.REACT_APP_AWS_REGION, credentials: creds });
    const command = new PutObjectCommand(target);
    const response = await client.send(command);
    updateProgress();
    return response;
  } catch (err) {
    return `Error uploading file: ${err}`;
  }
}

export async function uploadMultipart(fullPath, file, progressFunction, bucketname, totalFile) {
  try {
    // Step 1: Initiate the multipart upload
    const createMultipartUploadResponse = await s3
      .createMultipartUpload({
        Bucket: bucketname,
        Key: fullPath,
        ContentType: file.type
      })
      .promise();

    const uploadId = createMultipartUploadResponse.UploadId;

    // Step 2: Split the file into parts and upload each part
    const partSize = 10 * 1024 * 1024; // 10MB chunks
    const numParts = Math.ceil(file.size / partSize);

    const uploadPartPromises = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < numParts; i++) {
      const start = i * partSize;
      const end = Math.min(start + partSize, file.size);
      const blob = file.slice(start, end);

      uploadPartPromises.push(
        s3
          .uploadPart({
            Bucket: bucketname,
            Key: fullPath,
            PartNumber: i + 1,
            UploadId: uploadId,
            Body: blob
          })
          .on('httpUploadProgress', (evt) => {
            progressFunction(evt, totalFile);
          })
          .promise()
      );
    }

    const uploadedParts = await Promise.all(uploadPartPromises);

    // Step 3: Complete the multipart upload
    const completeMultipartUploadResponse = await s3
      .completeMultipartUpload({
        Bucket: bucketname,
        Key: fullPath,
        UploadId: uploadId,
        MultipartUpload: {
          Parts: uploadedParts.map((part, index) => ({
            ETag: part.ETag,
            PartNumber: index + 1
          }))
        }
      })
      .promise();

    return completeMultipartUploadResponse;
  } catch (err) {
    return `Multipart upload error: ${err}`;
  }
}

export async function listItem(fullPath, bucketName) {
  const input = {
    Bucket: bucketName,
    Prefix: `${fullPath}/`,
    Delimiter: '/'
  };
  const creds = {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
  };
  try {
    const client = new S3Client({ region: process.env.REACT_APP_AWS_REGION, credentials: creds });
    const command = new ListObjectsV2Command(input);
    const contents = await client.send(command);
    return contents;
  } catch (err) {
    return `Error: ${err}`;
  }
}

export async function getfile(fullPath, bucketName) {
  const input = {
    Bucket: bucketName,
    Key: fullPath,
    ResponseCacheControl: 'no-cache'
  };
  const creds = {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
  };
  try {
    const client = new S3Client({ region: process.env.REACT_APP_AWS_REGION, credentials: creds });
    const command = new GetObjectCommand(input);
    const response = await client.send(command);
    const data = await response.Body.transformToString();
    return { error: false, data };
  } catch (err) {
    return { error: true, data: `Error: ${err}` };
  }
}

export async function deletefile(fullPath, bucketName) {
  const input = {
    Bucket: bucketName,
    Key: fullPath
  };
  const creds = {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
  };
  try {
    const client = new S3Client({ region: process.env.REACT_APP_AWS_REGION, credentials: creds });
    const command = new DeleteObjectCommand(input);
    const response = await client.send(command);
    return { error: false, response };
  } catch (err) {
    return { error: true, data: `Error: ${err}` };
  }
}
