import React from 'react';
import './Error.scss';
import { Button } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';

function Error({ error, softDelete }) {
  const navigate = useNavigate();
  const handleNavigateToHome = () => {
    navigate('/home');
    window.location.reload();
  };
  return (
    <div className="error__container">
      {error}
      {softDelete ? (
        <Button
          className="iai_login_header_button"
          variant="outlined"
          onClick={handleNavigateToHome}>
          Back to Homepage
        </Button>
      ) : (
        ''
      )}
    </div>
  );
}

export default Error;
