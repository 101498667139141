import { createSlice, createAsyncThunk, isPending } from '@reduxjs/toolkit';
import fetchUtility from '../fetchUtility';
import { SERVER_URL } from '../../admin/constants/Constants';
import { downloadXLFile } from '../../utils';

export const getOrderRawDataActionCreator = createAsyncThunk('order/rawdata', async (payload) => {
  const { data } = await fetchUtility('get', `${SERVER_URL.ORDER_RAW_DATA}${payload.id}`, payload);
  return data;
});

export const getOrderInventoryDownloadActionCreator = createAsyncThunk(
  'order/inventory-download',
  async (payload) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.ORDER_INVENTORY_REPORT_DOWNLOAD}${payload.id}/export`,
      {},
      {},
      { responseType: 'arraybuffer' }
    );
    return data;
  }
);

export const getOrderConditionDownloadActionCreator = createAsyncThunk(
  'order/condition-download',
  async (payload) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.ORDER_CONDITION_REPORT_DOWNLOAD}${payload.id}/export`,
      {},
      {},
      { responseType: 'arraybuffer' }
    );
    return data;
  }
);

export const getOrderInventoryDataActionCreator = createAsyncThunk(
  'order/inventory-order',
  async (payload) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.ORDER_INVENTORY_DATA}${payload.id}`,
      payload
    );
    return data;
  }
);

export const getInventoryReportDataActionCreator = createAsyncThunk(
  'report/inventory',
  async (payload) => {
    const { data } = await fetchUtility('get', `${SERVER_URL.INVENTORY_REPORT}${payload.id}`);
    return data;
  }
);

export const getConditionReportDataActionCreator = createAsyncThunk(
  'report/condition',
  async (payload) => {
    const { id, searchQuery } = payload;
    const queryParams = new URLSearchParams(searchQuery);
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.CONDITION_REPORT}${id}?${queryParams?.toString()}`
    );
    return data;
  }
);

export const updateConditionReportDataActionCreator = createAsyncThunk(
  'report/updateCondition',
  async (payload) => {
    await fetchUtility(
      'put',
      `${SERVER_URL.UPDATE_CONDITION_REPORT}${payload.id}/${payload.mediaId}/${payload.frameId}/${payload.tagId}`,
      payload.data
    );
    // const { data } = await fetchUtility('get', `${SERVER_URL.CONDITION_REPORT}${payload.id}`);
    // return data;
    return payload;
  }
);
export const updateInventoryReportDataActionCreator = createAsyncThunk(
  'report/updateInventory',
  async (payload) => {
    await fetchUtility(
      'put',
      `${SERVER_URL.UPDATE_INVENTORY_REPORT}${payload.id}/${payload.mediaId}/${payload.frameId}/${payload.tagId}`,
      payload.data
    );
    // const { data } = await fetchUtility('get', `${SERVER_URL.INVENTORY_REPORT}${payload.id}`);
    // return data;
    return payload;
  }
);

export const updateOrderReportStatus = createAsyncThunk('order/update-status', async (payload) => {
  const { data } = await fetchUtility('post', `${SERVER_URL.ORDER_UPDATE_STATUS}`, payload);
  return data;
});

export const getOrderConditionalDataActionCreator = createAsyncThunk(
  'order/condition-order',
  async (payload) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.ORDER_CONDITIONAL_DATA}${payload.id}`,
      payload
    );
    return data;
  }
);

export const getOrderStatusDataActionCreator = createAsyncThunk('order/status', async (payload) => {
  const { data } = await fetchUtility('get', `${SERVER_URL.ORDER_STATUS}${payload.id}`);
  return data;
});

const isPendingAction = isPending(
  getOrderRawDataActionCreator,
  getOrderInventoryDataActionCreator,
  getOrderConditionalDataActionCreator,
  updateOrderReportStatus,
  // getInventoryReportDataActionCreator,
  // updateConditionReportDataActionCreator,
  getOrderStatusDataActionCreator
);

export const orderSlice = createSlice({
  name: 'order',
  messageData: '',
  initialState: {
    loading: null,
    rawData: null,
    orderInventory: null,
    orderConditionalReport: null,
    inventoryReport: null,
    conditionReport: null,
    globalConditionReport: null,
    previousClass: null,
    orderConditionStatus: null,
    orderInventoryStatus: null,
    orderRawDataStatus: null,
    orderConditionPurchaseStatus: null,
    orderInventoryPurchaseStatus: null,
    orderRawDataPurchaseStatus: null,
    conditionReportSearchValues: {
      projectType: '',
      assetGroup: '',
      activityDescription: '',
      unitIdentified: '',
      qty: '',
      assetCondition: '',
      comment: '',
      latitude: '',
      longitude: ''
    }
  },
  reducers: {
    clearMessage: {
      reducer: (state) => {
        state.messageData = null;
      }
    },
    conditionReportSearchValuesReducer: {
      reducer: (state, action) => {
        state.conditionReportSearchValues = {
          ...state.conditionReportSearchValues,
          ...action.payload
        };
      }
    },
    updateTaggedObjectInCondition: {
      reducer: (state) => {
        state.globalConditionReport = true;
      }
    },
    clearTaggedObjectCondition: {
      reducer: (state) => {
        state.globalConditionReport = false;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getOrderRawDataActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.rawData = action?.payload?.data;
    });
    builder.addCase(getOrderInventoryDataActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.orderInventory = action?.payload?.data;
    });
    builder.addCase(getOrderConditionalDataActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.orderConditionalReport = action?.payload?.data;
    });
    builder.addCase(getOrderInventoryDownloadActionCreator.fulfilled, (state, action) => {
      const fileName = `inventory.xlsx`;
      downloadXLFile(action.payload, fileName);
    });
    builder.addCase(getOrderConditionDownloadActionCreator.fulfilled, (state, action) => {
      const fileName = `condition.xlsx`;
      downloadXLFile(action.payload, fileName);
    });
    builder.addCase(getOrderStatusDataActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.orderConditionStatus = action?.payload?.order_condition;
      state.orderInventoryStatus = action?.payload?.order_inventory;
      state.orderRawDataStatus = action?.payload?.order_rawdata;
      state.orderConditionPurchaseStatus = action?.payload?.order_condition_status;
      state.orderInventoryPurchaseStatus = action?.payload?.order_inventory_status;
      state.orderRawDataPurchaseStatus = action?.payload?.order_rawdata_status;
      // state.orderConditionalReport = action?.payload?.data;
    });
    builder.addCase(updateOrderReportStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.messageData = action?.payload;
      const status = action?.meta?.arg?.status;
      const reportStatus = action?.meta?.arg?.reportStatus;
      let tab = 'orderInventory';
      if (action?.meta?.arg?.reportType === 'rawdata') {
        tab = 'rawData';
      }
      if (action?.meta?.arg?.reportType === 'conditional') {
        tab = 'orderConditionalReport';
      }
      state[tab] = { ...state[tab], report_status: reportStatus, status };
      // state.orderConditionalReport = action?.payload?.data;
    });
    builder.addCase(getInventoryReportDataActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.inventoryReport = action?.payload;
    });
    builder.addCase(getConditionReportDataActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.conditionReport = action?.payload;
    });
    builder.addCase(updateConditionReportDataActionCreator.fulfilled, (state) => {
      state.loading = false;
      // state.conditionReport = action?.payload;
      state.messageData = { message: 'Condition report updated' };
    });
    builder.addCase(updateInventoryReportDataActionCreator.fulfilled, (state) => {
      state.loading = false;
      // state.inventoryReport = action?.payload;
      state.messageData = { message: 'Inventory report updated' };
    });
    builder.addMatcher(isPendingAction, (state) => {
      state.loading = true;
    });
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );
  }
});

export const {
  clearMessage: clearMessageOrderActionCreator,
  conditionReportSearchValuesReducer: conditionReportSearchValuesActionCreator,
  updateTaggedObjectInCondition: updateTaggedKeyActionCreator,
  clearTaggedObjectCondition: clearTaggedKeyActionCreator
} = orderSlice.actions;

export default orderSlice.reducer;
