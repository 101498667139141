import { createSlice, createAsyncThunk, isPending } from '@reduxjs/toolkit';
import fetchUtility from '../fetchUtility';
import { SERVER_URL } from '../../admin/constants/Constants';

export const getDeletedProjectsListActionCreator = createAsyncThunk(
  'projects/deleted',
  async (payload) => {
    const { data } = await fetchUtility('get', `${SERVER_URL.DELETED_PROJECT_LIST}`, payload);
    return data;
  }
);

export const deleteProjectsActionCreator = createAsyncThunk('projects/delete', async (payload) => {
  const { data, status } = await fetchUtility(
    'delete',
    `${SERVER_URL.DELETE_PROJECT}/${payload.id}`,
    payload
  );
  return { data, status };
});

export const removeProjectsActionCreator = createAsyncThunk('projects/delete', async (payload) => {
  const { data, status } = await fetchUtility(
    'delete',
    `${SERVER_URL.REMOVE_PROJECT}/${payload.id}`,
    payload
  );
  return { data, status };
});

export const restoreProjectsActionCreator = createAsyncThunk('projects/delete', async (payload) => {
  const { data, status } = await fetchUtility(
    'put',
    `${SERVER_URL.RESTORE_PROJECT}/${payload.id}`,
    payload
  );
  return { data, status };
});

const isPendingAction = isPending(getDeletedProjectsListActionCreator, removeProjectsActionCreator);

export const deletedProjectSlice = createSlice({
  name: 'projects',
  initialState: {
    list: [],
    loading: null,
    error: null,
    messageData: null
  },
  reducers: {
    clearMessage: {
      reducer: (state) => {
        state.messageData = null;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getDeletedProjectsListActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action?.payload?.data;
    });
    builder.addCase(removeProjectsActionCreator.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addMatcher(isPendingAction, (state) => {
      state.loading = true;
    });
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );
  }
});

export const { clearMessage: clearMessageProjecstActionCreator } = deletedProjectSlice.actions;

export default deletedProjectSlice.reducer;
