/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { createSlice } from '@reduxjs/toolkit';

export const projectFilterSlice = createSlice({
  name: 'projectFilter',
  initialState: {
    searchInput: '',
    expireDate: [null, null],
    district: [],
    state: [],
    pageIndexNumber: 0
  },
  reducers: {
    projectFilter: {
      reducer: (state, action) => {
        for (const property in action.payload) {
          state[property] = action.payload[property];
        }
      }
    }
  }
});

export const { projectFilter: projectFilterActionCreator } = projectFilterSlice.actions;

export default projectFilterSlice.reducer;
