import React, { useState, useEffect } from 'react';
import { Box, AppBar, Toolbar, IconButton, Typography, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { AccountCircle } from '@mui/icons-material';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { useNavigate } from 'react-router-dom';
import { Auth, I18n } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from '../../components/LoadingOverlay/index';
import { logoutActionCreator } from '../../../redux/slices/user';

import NavBar from '../NavBar/NavBar';
import './Header.scss';
import EnvBanner from './EnvBanner/EnvBanner';
import UploadLoader from '../../components/UploadLoader/UploadLoader';

function Header({ user }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [role, setRole] = useState('NA');
  const [name, setName] = useState('NA');
  const [openUpload, setOpenUpload] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(async () => {
    const userSession = await user?.getSignInUserSession();
    const firstName = user?.attributes?.given_name || '';
    const lastName = user?.attributes?.family_name || '';
    const roles = userSession?.accessToken?.payload['cognito:groups'];
    if (roles.length) {
      setRole(roles[0]);
    }
    setName(`${firstName} ${lastName}`);
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSignout = async () => {
    await dispatch(logoutActionCreator());
    Auth.signOut().then(() => {
      navigate('/');
      window.location.reload();
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const environment = process.env.REACT_APP_ENV;
  const envBannerEl = environment ? <EnvBanner /> : '';
  const { uploadData, loadAddCordinateFrames } = useSelector((state) => state.projectsData);

  useEffect(async () => {
    if (uploadData.length > 0) {
      setOpenUpload(true);
    }
  }, [uploadData]);

  const handelCloseUpload = () => {
    setOpenUpload(false);
  };

  return (
    <>
      <AppBar elevation={0} className="header__appbar">
        {envBannerEl}
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className="header__menuicon"
            sx={{ display: { sm: 'none' } }}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" className="header__logo">
            infra ai
          </Typography>
          <IconButton size="large" className="header__user-icon">
            <AccountCircle />
          </IconButton>
          <Typography variant="p" noWrap component="div" className="header__user">
            {I18n.get(role)}
          </Typography>
          <IconButton size="large" onClick={handleMenu}>
            <ExpandMoreOutlinedIcon />
          </IconButton>
          <div className="user-menu">
            <Menu
              sx={{ top: '35px', right: '25px' }}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}>
              <MenuItem onClick={handleClose}>{name}</MenuItem>
              <MenuItem onClick={handleSignout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>

      <Box component="div" mt={8}>
        <NavBar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
        {openUpload && (
          <UploadLoader progressData={uploadData} handelCloseUpload={handelCloseUpload} />
        )}
        {loadAddCordinateFrames && <LoadingOverlay show />}
      </Box>
    </>
  );
}

export default Header;
