import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../slices/user';
import projectsReducer from '../slices/projects';
import usersReducer from '../slices/users';
import intelReducer from '../slices/intel';
import visionReducer from '../slices/vision';
import statusReducer from '../slices/status';
import myProjectsReducer from '../slices/myProjects';
import countryListReducer from '../slices/countryList';
import stateListReducer from '../slices/stateList';
import projectFilterReducer from '../slices/projectFilter';
import userFilterReducer from '../slices/userFilter';
import orderReducer from '../slices/order';
import mapReducer from '../slices/map';
import deletedProjectsReducer from '../slices/deletedProjects';
import assetsReducer from '../slices/assets';

export default configureStore({
  initialState: {},
  reducer: {
    status: statusReducer,
    userInfo: userReducer,
    projectsData: projectsReducer,
    usersData: usersReducer,
    intelData: intelReducer,
    visionData: visionReducer,
    myProjectsData: myProjectsReducer,
    deletedProjectsData: deletedProjectsReducer,
    countryListData: countryListReducer,
    stateListData: stateListReducer,
    projectFilterData: projectFilterReducer,
    userFilterData: userFilterReducer,
    orderData: orderReducer,
    map: mapReducer,
    assets: assetsReducer
  },
  devTools: process.env.NODE_ENV !== 'production'
});
