import React, { lazy } from 'react';

import { ReactComponent as HomeSvg } from '../assets/home.svg';
import { ReactComponent as HomeSelectedSvg } from '../assets/home-selected.svg';
// import { ReactComponent as IntelSvg } from '../assets/intel.svg';
// import { ReactComponent as IntelSelectedSvg } from '../assets/intel-selected.svg';
import { ReactComponent as AssetsSvg } from '../assets/assets.svg';
import { ReactComponent as AssetsSelectedSvg } from '../assets/assets-selected.svg';
import { ReactComponent as MyProjectsSvg } from '../assets/projects.svg';
import { ReactComponent as MyProjectsSelectedSvg } from '../assets/projects-selected.svg';
import { ReactComponent as VisionSvg } from '../assets/vision.svg';
import { ReactComponent as VisionSelectedSvg } from '../assets/vision-selected.svg';
import { ReactComponent as UserSvg } from '../assets/user.svg';
import { ReactComponent as UserSelectedSvg } from '../assets/user-selected.svg';

const Home = lazy(() => import('../admin/pages/home/Home'));
// const Marketplace = lazy(() => import('../admin/pages/maketplace/Marketplace'));
const Myprojects = lazy(() => import('../admin/pages/myprojects/Myprojects'));
const Users = lazy(() => import('../admin/pages/users/Users'));
// const Summary = lazy(() => import('../admin/pages/intel/summary/Summary'));
const ProjectMap = lazy(() => import('../admin/pages/vision/projectMap/ProjectMap'));
const Assets = lazy(() => import('../admin/pages/assetGroups/AssetGroups'));

const adminRoutes = [
  {
    path: '/home',
    exact: true,
    name: 'Home',
    element: Home,
    menutype: 'main',
    icon: <HomeSvg />,
    selectedIcon: <HomeSelectedSvg />
  },
  {
    path: '/myprojects',
    name: 'My Projects',
    element: Myprojects,
    menutype: 'main',
    icon: <MyProjectsSvg />,
    selectedIcon: <MyProjectsSelectedSvg />
  },
  {
    path: '/deletedprojects',
    name: 'Deleted Projects',
    element: Myprojects,
    menutype: 'main',
    icon: <MyProjectsSvg />,
    selectedIcon: <MyProjectsSelectedSvg />
  },
  {
    path: '/users',
    name: 'Users',
    element: Users,
    menutype: 'main',
    icon: <UserSvg />,
    selectedIcon: <UserSelectedSvg />
  },
  // {
  //   path: '/intel',
  //   name: 'Intel',
  //   element: Summary,
  //   menutype: 'sub',
  //   icon: <IntelSvg />,
  //   selectedIcon: <IntelSelectedSvg />
  // },
  {
    path: '/vision',
    name: 'Vision',
    element: ProjectMap,
    menutype: 'sub',
    icon: <VisionSvg />,
    selectedIcon: <VisionSelectedSvg />
  },
  {
    path: '/assets-road-side',
    name: 'Assets',
    element: Assets,
    menutype: 'main',
    icon: <AssetsSvg />,
    selectedIcon: <AssetsSelectedSvg />
  }
];

export default adminRoutes;
