/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-array-index-key */
import React, { Fragment, useState, useEffect } from 'react';
import {
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Box
} from '@mui/material';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate, useLocation } from 'react-router-dom';

import './NavBar.scss';
import routes from '../../../route/route';
import adminRoute from '../../../route/adminRoute';
import { hasPermission } from '../../../utils';
import { PERMISSIONS } from '../../constants/Constants';

function NavBar({ mobileOpen, handleDrawerToggle, ...props }) {
  const [selected, setSelected] = useState('/home');
  const { window } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuthenticator((context) => [context.user]);
  const currentUserRole = user?.signInUserSession?.accessToken?.payload['cognito:groups']?.[0];
  let path;
  if (hasPermission(currentUserRole, PERMISSIONS.DELETE_PROJECT)) {
    path = adminRoute;
  } else {
    path = routes;
  }
  let showSubMenu = true;
  if (
    location.pathname === '/' ||
    location.pathname.includes('/home') ||
    location.pathname.includes('/myprojects') ||
    location.pathname.includes('/deletedprojects') ||
    location.pathname.includes('/users') ||
    location.pathname.includes('/marketplace') ||
    location.pathname.includes('/assets') ||
    location.pathname.includes('/projects-requests')
  ) {
    showSubMenu = false;
  }

  useEffect(() => {
    if (location.pathname.includes('/myprojects')) setSelected('My Projects');
    else if (location.pathname.includes('/deletedprojects')) setSelected('Deleted Projects');
    else if (location.pathname.includes('/users')) setSelected('Users');
    else if (location.pathname.includes('/add-user')) setSelected('Users');
    else if (location.pathname.includes('/assets-road-side')) setSelected('Assets');
    else if (location.pathname.includes('/assets-side-walk')) setSelected('Assets');
    else if (
      location.pathname.includes('/intel') ||
      location.pathname.includes('/order') ||
      location.pathname.includes('/inventory') ||
      location.pathname.includes('/condition')
    )
      setSelected('Intel');
    else if (location.pathname.includes('/vision') || location.pathname.includes('/footage'))
      setSelected('Vision');
    else if (location.pathname.includes('/marketplace')) setSelected('Marketplace');
    else setSelected('Home');
  }, [location]);

  const container = window !== undefined ? () => window().document.body : undefined;
  const routeChange = (routeName) => {
    if (routeName.includes('/intel') || routeName.includes('/vision')) {
      const id = location.pathname.split('/').pop();
      navigate(`${routeName}/${id}`);
    } else {
      navigate(routeName);
    }
  };

  const drawer = (
    <div className="navbar__drawer">
      <Toolbar sx={{ display: { xs: 'none', sm: 'block' } }} className="drawer__top" />
      <List className="navbar__list">
        {path.map((route, index) => (
          <Fragment key={index}>
            {route.menutype === 'sub' && showSubMenu === false ? (
              ''
            ) : (
              <>
                <ListItem
                  key={index}
                  button
                  className="navbar__listitem"
                  onClick={() => routeChange(route.path)}>
                  <MenuItem button="true" selected={selected === route.name}>
                    <ListItemIcon className={`navbar__listitem-icon `}>
                      {selected === route.name ? route.selectedIcon : route.icon}
                    </ListItemIcon>
                  </MenuItem>
                  <ListItemText
                    key={index}
                    primary={route.name}
                    className="navbar__listitem-text"
                  />
                </ListItem>
                <div className="navbar__divider" />
              </>
            )}
          </Fragment>
        ))}
      </List>
    </div>
  );

  return (
    <Box component="nav" className="navbar__container">
      <Drawer
        container={container}
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true
        }}
        sx={{
          display: { xs: 'block', sm: 'none' }
        }}>
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' }
        }}
        open>
        {drawer}
      </Drawer>
    </Box>
  );
}

export default NavBar;
