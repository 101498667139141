import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

export const userSlice = createSlice({
  name: 'user',
  initialState: null,
  reducers: {
    logout: {
      reducer: () => {
        Cookies.remove('token');
        return null;
      }
    }
  }
});

export const { logout: logoutActionCreator } = userSlice.actions;

export default userSlice.reducer;
