import axios from 'axios';
import { useMatch, useLocation } from 'react-router-dom';
import { ROLE_PERMISSIONS, STATES_DISTRICTS } from '../admin/constants/Constants';

/* eslint-disable import/prefer-default-export */
export const trimStringHandler = (string, length) =>
  string?.length > length ? `${string.substring(0, length)}...` : string;

const downloadFile = (blob, fileName) => {
  const link = document.createElement('a');
  link.href = blob;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const downloadAttachment = (attachments = [], extension = 'pdf') => {
  for (let i = 0; i < attachments.length; i += 1) {
    axios
      .request({
        url: attachments[i].attachment,
        method: 'GET',
        responseType: 'arraybuffer'
      })
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        downloadFile(downloadUrl, `${attachments[i].type}.${extension}`);
      });
  }
};

export const filterDistricts = (states = [], districts = []) => {
  let selectedDistricts = [];
  states.forEach((state) => {
    const listedDistricts = STATES_DISTRICTS[state];
    districts.forEach((d) => {
      const extractedDistricts = d.split(', ');
      const filteredDistricts = listedDistricts.filter((listedDistrict) =>
        extractedDistricts.includes(listedDistrict)
      );
      selectedDistricts = [...selectedDistricts, ...filteredDistricts];
    });
  });
  return [...new Set(selectedDistricts)];
};

export const downloadXLFile = (payload, fileName) => {
  const url = window.URL.createObjectURL(
    new Blob([payload], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
    })
  );
  downloadFile(url, fileName);
};

export const getSearchList = (groups = []) =>
  groups.map((groupData) => ({
    label: groupData.group,
    key: groupData.group.toLowerCase().replace(/\s+/g, '-')
  }));

export const currencyFormatter = (str, type = 'int') => {
  const data =
    type === 'int' ? parseInt(str.replace('$', ''), 10) : parseFloat(str.replace('$', ''));
  return data.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });
};

export const getVideoKey = (videoObj, s3Instance) => {
  if (!videoObj || !s3Instance) return '';
  const { video: videourl } = videoObj;

  const videoUrlParts = videourl.split('/');
  const key = videoUrlParts.slice(3).join('/');
  const signedUrlExpireSeconds = 60 * 180;
  const params = {
    Bucket: videoUrlParts[2].replace('.s3.amazonaws.com', ''),
    Key: key,
    Expires: signedUrlExpireSeconds
  };
  const video = s3Instance.getSignedUrl('getObject', params);
  return { ...videoObj, video };
};

export const hasPermission = (role, permission) => {
  const rolePermissions = ROLE_PERMISSIONS[role]?.permissions;
  return rolePermissions?.includes(permission);
};

export const getRestucutredClassItemList = (classItemList) => {
  const restructuredClassItemListData = [];
  if (classItemList && classItemList?.length > 0) {
    classItemList.forEach((gr) => {
      if (gr?.classes && gr.classes.length > 0) {
        gr?.classes?.forEach((cl) => {
          if (cl?.items && cl?.items?.length > 0) {
            cl?.items?.forEach((item) => {
              const objectAlreadyExist = restructuredClassItemListData.some(
                (el) => el.item === item && el.class === cl?.class && el.group === gr?.group
              );
              if (!objectAlreadyExist) {
                restructuredClassItemListData.push({
                  group: gr?.group,
                  class: cl?.class,
                  item,
                  color_code: cl?.color_code
                });
              }
            });
          } else if (cl?.class) {
            const objectAlreadyExist = restructuredClassItemListData.some(
              (el) => el.class === cl?.class && el.group === gr?.group
            );
            if (!objectAlreadyExist) {
              restructuredClassItemListData.push({
                group: gr?.group,
                class: cl?.class,
                color_code: cl?.color_code
              });
            }
          }
        });
      }
    });
  }
  return restructuredClassItemListData;
};

export const getColorCode = (option, classArray) => {
  const objectClassDetails = classArray.find(
    (cl) => cl?.group === option?.object_group && cl?.class === option?.object_class
  );
  return objectClassDetails?.color_code;
};

const sortAscending = (array, property) =>
  array.slice().sort((a, b) => {
    if (a[property] < b[property]) return -1;
    if (a[property] > b[property]) return 1;
    return 0;
  });

const sortDescending = (array, property) =>
  array.slice().sort((a, b) => {
    if (a[property] > b[property]) return -1;
    if (a[property] < b[property]) return 1;
    return 0;
  });

export const handleSort = (order, data, field) => {
  const sortedItems = order === 'asc' ? sortAscending(data, field) : sortDescending(data, field);

  return sortedItems;
};

export const formatDate = (dateString) => {
  if (dateString) {
    const date = new Date(dateString);
    const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }
  return 'NA';
};

export const useIsPathMatch = (path) => {
  const location = useLocation();
  const match = useMatch(path);

  if (!match) return false;

  // Check if the pathname (without query params) matches exactly
  const pathnameWithoutQuery = location?.pathname?.split('?')[0];
  const isExactMatch = pathnameWithoutQuery === match.pathname;

  // Check if there are any query parameters
  const hasQueryParams = location?.search !== '';

  // Return true only if it's an exact match and there are no query parameters
  return isExactMatch && !hasQueryParams;
};

export const removeTaggedPrefix = (text) => {
  if (text && text.startsWith('Tagged ')) {
    return text.replace('Tagged ', '');
  }
  return text;
};

export const removeTaggedPrefixAndTrim = (text, length) => {
  const realObjectName = removeTaggedPrefix(text);
  return trimStringHandler(realObjectName, length);
};
