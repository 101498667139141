import { createSlice, createAsyncThunk, isPending } from '@reduxjs/toolkit';
import fetchUtility from '../fetchUtility';
import { SERVER_URL } from '../../admin/constants/Constants';

export const getUsersListActionCreator = createAsyncThunk('users/list', async (payload) => {
  const { data, status } = await fetchUtility('get', `${SERVER_URL.USER_LIST}`, payload);
  return { data, status };
});

export const getUserActionCreator = createAsyncThunk('users/item', async (payload) => {
  const { data } = await fetchUtility('get', `${SERVER_URL.USER_LIST}/${payload}`, payload);
  return data;
});

export const addUserActionCreator = createAsyncThunk('users/add', async (payload) => {
  const { data, status } = await fetchUtility('post', `${SERVER_URL.USER_LIST}`, payload);
  return { data, status };
});

export const deleteUserActionCreator = createAsyncThunk('users/remove', async (payload) => {
  const { data, status } = await fetchUtility(
    'delete',
    `${SERVER_URL.USER_LIST}/${payload.id}`,
    payload
  );
  return { data, status };
});

export const editUserActionCreator = createAsyncThunk('users/edit', async (payload) => {
  const { data, status } = await fetchUtility('put', `${SERVER_URL.USER_LIST}`, payload);
  return { data, status };
});

const isPendingAction = isPending(getUsersListActionCreator, getUserActionCreator);

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    list: [],
    loading: null,
    error: null,
    messageData: null
  },
  reducers: {
    clearMessage: {
      reducer: (state) => {
        state.messageData = null;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUsersListActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action?.payload?.data?.data;
    });
    builder.addCase(getUserActionCreator.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(addUserActionCreator.fulfilled, (state, action) => {
      state.messageData = action?.payload;
    });
    builder.addCase(deleteUserActionCreator.fulfilled, (state, action) => {
      state.messageData = action?.payload;
    });
    builder.addCase(editUserActionCreator.fulfilled, (state, action) => {
      state.messageData = action?.payload;
    });
    builder.addCase(deleteUserActionCreator.rejected, (state, action) => {
      state.messageData = action?.payload;
    });
    builder.addCase(editUserActionCreator.rejected, (state, action) => {
      state.messageData = action?.payload;
    });
    builder.addCase(getUserActionCreator.rejected, (state) => {
      state.loading = false;
    });
    builder.addMatcher(isPendingAction, (state) => {
      state.loading = true;
    });
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );
  }
});

export const { clearMessage: clearMessageUsersActionCreator } = usersSlice.actions;

export default usersSlice.reducer;
