/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Collapse, Divider, List, ListItem, ListItemIcon, Typography } from '@mui/material';
import { ReactComponent as ExpandLess } from '../../../assets/expandLess.svg';
import { ReactComponent as ExpandMore } from '../../../assets/expandMore.svg';
import { ReactComponent as Close } from '../../../assets/close.svg';
import { ReactComponent as UploadComplete } from '../../../assets/fileComplete.svg';
import './UploadLoader.scss';

function CircularProgressWithLabel({ value }) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" size="2rem" value={value} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Typography variant="caption" component="div" sx={{ color: 'black', fontSize: '10px' }}>
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export default function UploadLoader({ progressData, handelCloseUpload }) {
  const [openUpload, setOpenUpload] = useState(false);
  const [completedFile, setCompletedFile] = useState([]);
  const { uploadData } = useSelector((state) => state.projectsData);
  const handleToggle = () => {
    setOpenUpload(!openUpload);
  };
  useEffect(async () => {
    const completedOne = [];
    uploadData.forEach((element) => {
      if (!element.isUploading) {
        completedOne.push(element);
      }
    });
    setCompletedFile(completedOne);
  }, [uploadData]);

  return (
    <Box className="upload-progress-container">
      <div className="upload-bar">
        <Typography className="upload-content">
          Upload Complete {completedFile.length}/{uploadData.length}
        </Typography>
        {openUpload ? (
          <ExpandLess onClick={handleToggle} className="upload-expand" />
        ) : (
          <ExpandMore onClick={handleToggle} className="upload-expand" />
        )}
        {completedFile.length === uploadData.length && (
          <Close className="upload-expand" onClick={handelCloseUpload} />
        )}
      </div>
      <Collapse in={openUpload}>
        <List className="upload-list-container">
          {progressData?.map((file, index) => (
            <Fragment key={file.projectName}>
              <ListItem className="upload-item-container">
                <ListItemIcon>
                  {file.uploadProgress !== 100 ? (
                    <CircularProgressWithLabel value={file.uploadProgress} />
                  ) : (
                    <UploadComplete style={{ color: 'green' }} />
                  )}
                </ListItemIcon>
                <Box sx={{ display: 'block' }}>
                  {/* <Box className="upload-file-name">{file.file}</Box>
                  <Box className="upload-file-size">{file.size} MB</Box> */}
                  <Box sx={{ display: 'flex' }}>
                    {/* <Box className="upload-file-size upload-in">in</Box> */}
                    <Box className="upload-project-name"> {file.projectName}</Box>
                  </Box>
                  <Box sx={{ display: 'flex' }}>
                    <Box className="upload-project-name">
                      {' '}
                      Time Taken :{' '}
                      {file?.timeTaken
                        ? Number.parseFloat(file?.timeTaken / 60000).toFixed(2)
                        : null}{' '}
                      {file?.timeTaken ? 'min' : null}
                    </Box>
                  </Box>
                </Box>
              </ListItem>
              {(progressData?.length ?? 0) - 1 !== index && <Divider />}
            </Fragment>
          ))}
        </List>
      </Collapse>
    </Box>
  );
}
