import React from 'react';
import './EnvBanner.scss';

const bannerConfig = {
  LOCALHOST: {
    bgCls: 'localhostBg'
  },
  DEV: {
    bgCls: 'devBg'
  },
  QA: {
    bgCls: 'qaBg'
  }
};

export default function EnvBanner() {
  const environment = process.env.REACT_APP_ENV;
  let bannerEl = '';

  if (environment) {
    const envName = environment.toUpperCase();
    const cls = `envBannerLayout ${bannerConfig[envName].bgCls}`;
    bannerEl = <div className={cls}>{envName}</div>;
  }

  return bannerEl;
}
