import React from 'react';

import './index.scss';

function LoadingOverlay({ show = false }) {
  return (
    <section style={{ display: show ? 'block' : 'none' }} className="loader-container">
      <div className="lds-roller">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </section>
  );
}

export default LoadingOverlay;
