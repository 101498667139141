import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import fetchUtility from '../fetchUtility';
import { SERVER_URL } from '../../admin/constants/Constants';

export const getCountryDetailsActionCreator = createAsyncThunk('country/list', async (payload) => {
  const { data } = await fetchUtility('post', `${SERVER_URL.COUNTRY_LIST}`, payload);
  return data;
});

export const countryListSlice = createSlice({
  name: 'country',
  initialState: {
    list: null,
    loading: null,
    error: null
  },
  extraReducers: (builder) => {
    builder.addCase(getCountryDetailsActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action?.payload?.data;
    });
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );
  }
});

export default countryListSlice.reducer;
