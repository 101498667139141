/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { createSlice } from '@reduxjs/toolkit';

export const userFilterSlice = createSlice({
  name: 'userFilter',
  initialState: {
    searchInput: '',
    pageIndexNumber: 0
  },
  reducers: {
    userFilter: {
      reducer: (state, action) => {
        for (const property in action.payload) {
          state[property] = action.payload[property];
        }
      }
    }
  }
});

export const { userFilter: userFilterActionCreator } = userFilterSlice.actions;

export default userFilterSlice.reducer;
