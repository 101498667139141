import { connect } from 'react-redux';
import React from 'react';
import Error from './Error';
import { ERROR_MSGS } from '../../constants/Constants';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, softDelete: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidMount() {
    const { apiError } = this.props;
    this.createErrors(apiError);
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { apiError } = props;
    this.createErrors(apiError);
  }

  componentDidCatch() {
    this.setState({ hasError: true, error: ERROR_MSGS.other });
  }

  createErrors = (apiError) => {
    if (apiError && apiError.includes('401')) {
      this.setState({
        hasError: true,
        error: ERROR_MSGS[401]
      });
    }
    if (apiError && apiError.includes('403')) {
      this.setState({
        hasError: true,
        error: ERROR_MSGS[403],
        softDelete: true
      });
    }
  };

  render() {
    const { hasError, error, softDelete } = this.state;
    const { children } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <Error error={error} softDelete={softDelete} />
        </div>
      );
    }

    return children;
  }
}

const mapStateToProps = (state) => {
  const { intelData } = state;
  return {
    apiError: intelData.error
  };
};

export default connect(mapStateToProps)(ErrorBoundary);
