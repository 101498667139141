import { createSlice, createAsyncThunk, isPending } from '@reduxjs/toolkit';
import fetchUtility from '../fetchUtility';
import { SERVER_URL } from '../../admin/constants/Constants';

export const getIntelSummaryActionCreator = createAsyncThunk('intel/summary', async (payload) => {
  const { data } = await fetchUtility('get', `${SERVER_URL.INTEL_SUMMARY}${payload.id}`, payload);
  return data;
});

export const getIntelDescriptionActionCreator = createAsyncThunk(
  'intel/description',
  async (payload) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.INTE_DESCRIPTION}${payload.id}`,
      payload
    );
    return data;
  }
);

export const getIntelTimelineActionCreator = createAsyncThunk('intel/timeline', async (payload) => {
  const { data } = await fetchUtility('get', `${SERVER_URL.INTEL_TIMELINE}${payload.id}`, payload);
  return data;
});

export const getIntelAwardActionCreator = createAsyncThunk('intel/award', async (payload) => {
  const { data } = await fetchUtility('get', `${SERVER_URL.INTEL_AWARD}${payload.id}`, payload);
  return data;
});

export const getIntelAnalysisActionCreator = createAsyncThunk('intel/analysis', async (payload) => {
  const { data } = await fetchUtility('get', `${SERVER_URL.INTEL_ANALYSIS}${payload.id}`, payload);
  return data;
});

export const getIntelContactsActionCreator = createAsyncThunk('intel/contacts', async (payload) => {
  const { data } = await fetchUtility('get', `${SERVER_URL.INTEL_CONTACT}${payload.id}`, payload);
  return data;
});

const isPendingAction = isPending(
  getIntelSummaryActionCreator,
  getIntelDescriptionActionCreator,
  getIntelTimelineActionCreator,
  getIntelAwardActionCreator,
  getIntelAnalysisActionCreator,
  getIntelContactsActionCreator
);

const initialState = () => ({
  summary: null,
  description: null,
  timeline: null,
  award: null,
  analysis: null,
  contacts: null,
  loading: false,
  error: null
});

export const intelSlice = createSlice({
  name: 'intel',
  initialState: initialState(),
  reducers: {
    resetState: () => initialState()
  },
  extraReducers: (builder) => {
    builder.addCase(getIntelSummaryActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.summary = action?.payload?.data;
    });
    builder.addCase(getIntelDescriptionActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.description = action?.payload?.data;
    });
    builder.addCase(getIntelTimelineActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.timeline = action?.payload?.data;
    });
    builder.addCase(getIntelAwardActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.award = action?.payload?.data;
    });
    builder.addCase(getIntelAnalysisActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.analysis = action?.payload?.data;
    });
    builder.addCase(getIntelContactsActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.contacts = action?.payload?.data;
    });
    builder.addMatcher(isPendingAction, (state) => {
      state.loading = true;
    });
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );
  }
});

export const { resetState: resetStateIntelActionCreator } = intelSlice.actions;

export default intelSlice.reducer;
