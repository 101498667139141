/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, isPending } from '@reduxjs/toolkit';
import fetchUtility from '../fetchUtility';
import { SERVER_URL } from '../../admin/constants/Constants';

export const getUserProjects = createAsyncThunk('user/projects', async (payload) => {
  const { data } = await fetchUtility('post', `${SERVER_URL.USER_PROJECTS}`, payload);
  return data;
});

export const updateProjectStatus = createAsyncThunk(
  'user/projects/update/status',
  async (payload) => {
    const { data } = await fetchUtility('patch', `${SERVER_URL.PROJECT_STATUSES}`, payload);
    return data;
  }
);

export const projectsReportRequested = createAsyncThunk(
  'user/projects/report/requested',
  async (payload) => {
    const { data } = await fetchUtility('post', `${SERVER_URL.REPORT_REQUESTED}`, payload);
    return data;
  }
);

export const projectsReportUser = createAsyncThunk('user/projects/report/user', async (payload) => {
  const { data } = await fetchUtility('post', `${SERVER_URL.PROJECT_REPORT_USER}`, payload);
  return data;
});

const isPendingAction = isPending(
  getUserProjects,
  updateProjectStatus,
  projectsReportRequested,
  projectsReportUser
);

export const statusSlice = createSlice({
  name: 'status',
  initialState: {
    userProjects: null,
    updateStatus: null,
    projectRequested: [],
    projectUser: [],
    error: null
  },
  extraReducers: (builder) => {
    builder.addCase(getUserProjects.fulfilled, (state, action) => {
      state.loading = false;
      state.userProjects = action?.payload?.data;
    });
    builder.addCase(updateProjectStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.updateStatus = action?.payload?.data;
    });
    builder.addCase(projectsReportRequested.fulfilled, (state, action) => {
      state.loading = false;
      state.projectRequested = action?.payload?.data;
    });
    builder.addCase(projectsReportUser.fulfilled, (state, action) => {
      state.loading = false;
      state.projectUser = action?.payload?.data;
    });
    builder.addMatcher(isPendingAction, (state) => {
      state.loading = true;
    });
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );
  }
});

export default statusSlice.reducer;
